.SearchResults {
  &#search-results-items {
    @include breakpoint(medium down) {
      margin-top: 0;
    }
    .search-results-info {
      margin-bottom:45px;
      p {
        font-weight: 700;
      }
      
    }
    UL.accordion-list-items {
      @extend %ul;
      margin-bottom: 50px;
      > li {
        padding: 0;
        border: 1px solid brand-color(grey-light);
        margin: -1px;
        position: relative;
        &.accordion-list-item {
          .light-blue-bar {
            display: none;
          }
          &.opened {
            .light-blue-bar {
              @include breakpoint(large) {
                display: block;
                height: 100%;
                position: absolute;
                left: 0;
                width: 5px;
                background: brand-color(primary-light-blue);
              }
            }
          }
          .accordion-list-hdr {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background: brand-color(mute-blue);
            padding: 10px 20px;
            transition: background 0.1s ease-in-out;
            text-decoration: none;
            &:hover {
              color: brand-color(primary-blue);
            }
            &.opened {
              background: transparent;
            }
            .accordion-hdr-title h2 {

              font-size: $text-size-large-lh;
              line-height: $header-line-height;
              font-weight: 700;
              color: brand-color(primary-blue);
              padding-right: 15px;
              margin-bottom: 0;
              span.hdr-subtitle {
                display: block;
                font-size: rem-calc(16);
                font-weight: normal;
                color: brand-color(primary-dark);
              }
            }
            i {
              font-size: rem-calc(22);
              &.icon-arrow-down {
                color: brand-color(body);
              }
              &.icon-arrow-up {
                color: brand-color(primary-light-blue);
              }
            }
          }
          .accordion-list-content {
            transform-origin: top;
            padding: 0;
            visibility: hidden;
            max-height: 0;
            overflow: hidden;
            &.opened {
              padding: 20px;
              visibility: visible;
              max-height: 6000px;
            }
            h2 {
              @include h2();
              color: brand-color(primary-light-blue);
              line-height: 2.25rem;
              margin-bottom: 1.5rem;
            }
            p {
              margin-top: 12px;
              margin-bottom: 12px;
              color: brand-color(body);
            }
            // a {
            //   color: brand-color(primary-light-blue);
            //   &:hover {
            //     color:brand-color(primary-light-blue-hover);
            //   }
            // }
            ul {
              margin: 1.625rem 0 1.5rem;
              &.tick-list {
                li {
                  &:before {
                    background-image: url(/themes/dbi/img/global/check.png?la=en&h=23&w=29);
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: "";
                    display: inline-block;
                    height: 1.8125rem;
                    position: absolute;
                    left: 2.375rem;
                    top: 0;
                    width: 1.5125rem;
                  }
                }
              }
              &.exclamation-list {
                li {
                  &:before {
                    background-image: url(/themes/dbi/img/global/exclamation-mark.png?la=en);
                    background-position: center center;
                    background-color: transparent;
                    background-repeat: no-repeat;
                    background-size: contain;
                    content: '';
                    display: inline-block;
                    height: 1.8125rem;
                    position: absolute;
                    left: 2.375rem;
                    top: 0;
                    width: 1.8125rem;
                  }
                }
              }
               li {
                padding-left: 6.0625rem;
                position: relative;
                &:before {
                  display: inline-block;
                  background-color: brand-color(primary-light-blue);
                  content: "";
                  height: 0.4375rem;
                  position: absolute;
                  left: 3rem;
                  top: 0.5rem;
                  width: 0.4375rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
