.HelpCentreSearch {
  background-color: brand-color(grey-lighter);
  min-height: 220px;
  margin: 0;
  .help-centre-search-container {
    padding-top: 25px;
    .help-centre-search-title-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;

      .help-centre-search-title {
        font-size: $text-size-xxlarge;
        font-weight: 700;
        color: brand-color(primary-blue);
        padding-left: 10px;
        letter-spacing: 1px;
        margin-bottom: 0;
        @include breakpoint(small down) {
          font-size: rem-calc(30);
        }
      }
      .round-icon {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(small down) {
          width: 50px;
          height: 50px;
        }
        &.green {
          background-color: brand-color(primary-green-dark);
        }
        &.light-blue {
          background-color: brand-color(primary-light-blue);
        }
        &.blue {
          background-color: brand-color(primary-blue);
        }
        i {
          font-size: rem-calc(57);
          color: #fff;
          @include breakpoint(small down) {
            font-size: rem-calc(35);
          }
        }
      }
    }
    #help-centre-search-form {
      .form-elements {
        position: relative;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
          flex-direction: row;
          justify-content: space-between;
        }

        INPUT.txt-query {
          flex-grow: 1;
          height: 45px;
          background: #fff;

          @include breakpoint(medium) {
            flex-direction: row;
            justify-content: space-between;
          }
        }
        i.icon-search {
          font-size: 1.5em;
          position: absolute;
          top: 11px;
          right: 15px;
          cursor: pointer;
        }
        .btn-submit {
          flex-grow: 0;
          flex-shrink: 0;
          @include breakpoint(medium) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
          &:before {
            margin-right: 10px;
          }
        }
      }
      .categories-wrapper, .search-wrapper {
        max-width: $desktop-header-maxwidth;
        #categories-nav {
          @include unlist();
          display:flex;
          position: relative;
          width: 100%;
          height: 55px;
          flex-direction: row;
          justify-content: space-between;
          transition: all 0.4s ease-in-out;

          @include breakpoint(large down) {
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
          }
          
          li {
            cursor: pointer;
            border: 6px solid transparent;
            border-left: 0;
            border-right: 0;
            transition: border-bottom-color 0.2s ease-out;
            padding-bottom: 15px;
            @include breakpoint(medium down) {
              padding-bottom: 5px;
            }
            &.active,
            &:hover {
              border-bottom-color: brand-color(primary-light-blue);
              @include breakpoint(medium down) {
                border-bottom-color: transparent;
              }
              a {
                color: brand-color(primary-light-blue);
         
                @include breakpoint(medium down) {
                  color: brand-color(primary-blue);
                }
              }
            }
            a {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              font-weight: 700;
              font-size: rem-calc(18);
              letter-spacing: 0.5px;
              height: 100%;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;
              color: brand-color(primary-blue);
              text-decoration: none;
              &:hover {
                color: brand-color(primary-light-blue);
              }
              i {
                font-size: rem-calc(16);
                display: none;
                @include breakpoint(medium down) {
                  display: block;
                }
              }
            }
          }
        }
        .categories-header-wrapper, .search-header-wrapper  {
          color: brand-color(primary-blue);
          width: 100%;
          flex-direction: row;
          align-items: center;
          height: 55px;
          margin-top: 20px;
          padding-bottom: 20px;
          transition: all 0.4s ease-in-out;
          .categories-header-container, .search-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            .search-results-info {
              color: brand-color(body);
              font-weight:700;
            }
            .category-title {
              color: brand-color(primary-light-blue);
              font-size: $text-size-larger-lh;
              font-weight: 700;
            }
            .categories-back-link-mobile, .categories-back-link {
              cursor: pointer;
              font-weight: 700;
              display: flex;
              align-items: center;
              text-decoration: none;
              span {
                text-decoration: underline;
                @include breakpoint(medium down) {
                  color: brand-color(body);
                  text-decoration: none;
                }
              }

              i {
                margin-right: 10px;
                @include breakpoint(medium down) {
                  margin-left: 10px;
                }
                color: brand-color(body);
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}


