.jquery-modal {
  z-index: layer(modal);
  background: brand-color(primary-blue);
  &.blocker {
    z-index: layer(site-blocker);
    background: brand-color(primary-blue);

    &:before {
    }
  }
  .modal {
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
    background: transparent;
    z-index: layer(modal);
    max-width: 650px;

    .blurb-container {
      margin: 20px 0 40px 0;
      font-size:  rem-calc(24);
      color: #fff;
      text-align: center;
      @include breakpoint(medium only) {
        margin: 40px 0 10px 0;
        font-size: rem-calc(20);
      }
    }
    .modal-container {
      height: 370px;
      @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
        height: 240px;
      }
    }

    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
  .video-modal-header {
    position: fixed;
    width: 100%;
    background: white;
    top: 0;
    left: 0;
    .header-top {
      height: 120px;
      max-width: $desktop-header-maxwidth;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
        height: 70px;
      }
     
      .logo-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 90px;
        @include breakpoint(small down) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        a {
          outline: none;
          &.logo {
            img {
              margin: 0;
              height: 100%;
              width: 160px;
              outline: none;
              @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
                width: 120px;
              }
              @include breakpoint(small down) {
                width: 120px;
                height: auto;
              }
            }
          }
        }
        .company-title {
          color: brand-color(primary-blue);
          font-size: rem-calc(22);
          padding-left: 20px;
          padding-top: 38px;
          font-weight: bold;
          
          @media screen and #{breakpoint(medium down)} and #{breakpoint(landscape)} {
            padding-left: 10px;
            padding-top: 10px;
            font-size: rem-calc(18);
          }
          
          @include breakpoint(small down) {
            font-size: rem-calc(14);
            padding-top: 10px;
            padding-left: 0px;
          }
        }
      }
      a.close-modal {
        position: unset;
        top: unset;
        right: unset;
        width: auto;
        height: auto;
        background: none;
        color: brand-color(primary-blue);
        text-align: left;
        line-height: 35px;
        font-size: 14px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        text-indent: 0px;
        display: flex;
        align-items: center;
        & i {
          color: brand-color(primary-light-blue);
          margin-left: 10px;
        }
        &:hover {
          color: brand-color(primary-blue-hover);
        }
      }
    }
  }
}
