// Shared Header styles here.
.Alert {
  &#alert {
    background: brand-color(status-orange);
    color: #fff;
    overflow: hidden;
    z-index: layer(alert);
    height: 0px; // initial state so it'll be hidden by default;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    display:none;
    height:100%;
    .inner {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      max-width: $desktop-header-maxwidth;
      padding: 0 20px;
    }
    &.important {
      background: brand-color(status-orange);
    }
    &.green {
      background: brand-color(primary-green-dark);
    }
    &.caution {
      background: brand-color(status-orange);
    }
    .btn-close {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      cursor: pointer;
      // outline: none;
      .btn-close-txt {
        font-size: $text-size-small;
        color: #fff;
        border-bottom: 1px dashed #fff;
        text-transform: uppercase;
        margin: 5px 10px 3px 0px;
        padding: 0;
        @include breakpoint(medium down) {
          display: none;
        }
        &:hover {
          color: brand-color(grey-lightest);
        }
      }
      .icon-alert-close {
        border-bottom: none;
        font-size:  rem-calc(20);
        &:hover {
          color: brand-color(grey-lightest);
        }
      }
    }

    i {
      color: #fff;
      &.icon-alert {
        font-size:  rem-calc(30);
        margin-right: 15px;
        @include breakpoint(medium down) {
          font-size: 50px;
        }
      }
    }

    .text-wrapper {
      margin: 16px 0px;
      line-height: 1em;
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items:center;
      @include breakpoint(medium down) {
        flex-direction: column;
        align-items:flex-start;
      }
      .heading {
        font-size: $text-size-larger;
        margin-right: 20px;
        line-height:24px;
        white-space: nowrap;
        font-weight: bold;
        @include breakpoint(medium down) {
          margin-right: 0px;
          margin-bottom: 10px;
          white-space: normal;
        }
        a {
          color: #fff;   
          text-decoration: none;       
          &:hover {
            text-decoration: underline;
            color: #fff;
          }
        }
        // &:hover {
        //   text-decoration: underline;
        // }
      }
      .text {
        font-size: $text-size-medium;
        margin-right: 20px;
        a {
          color: #fff;
          text-decoration: none; 
          &:hover {
            text-decoration: underline;
            color: #fff;
          }
        }
      }

    }
  }
}
