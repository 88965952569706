.button {
  background-color: transparent;
  border-radius: $border-radius;
  box-sizing: border-box;
  border: 1px solid;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  color: brand-color(primary-blue);
  overflow: visible;
  text-align: center;
  transition: all 0.1s ease-in-out;
  text-decoration: none !important;
  font-family: $varela-font-family !important;

  // Small buttons
  &.small {
    @extend %button-small;
  }

  // Medium buttons
  &.medium {
    @extend %button-medium;
  }

  // Large buttons
  &.large {
    @extend %button-large;
  }

  // Colours
  &.white {
    @extend %button-white;
  }

  &.blue {
    @extend %button-blue;
  }

  &.green {
    @extend %button-green;
  }

  &.light-blue {
    @extend %button-light-blue;
  }

  &.grey-border {
    @extend %button-grey-border;
  }

  &.light-blue-border {
    @extend %button-light-blue-border;
  }

  &.button-icon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    &.justify-start {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }
    &.icon-left-margin {
      i {
        margin-right: 10px;
      }
    }
    &.icon-right-margin {
      i {
        margin-left: 10px;
      }
    }

    &.icon-lg {
      i {
        font-size: rem-calc(30);
      }
    }
  }

  // pseudo states
  &:visited {
    color: brand-color(primary-blue);
  }

  &:active {
    color: brand-color(primary-blue);
  }

  &:hover:not([disabled]) {
    color: #fff;
    background-color: brand-color(primary-light-blue);
  }

  &:focus {
    color: #fff;
    background-color: brand-color(primary-blue);
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.full-width {
    width: 100%;
  }
}

@mixin button-small() {
  font-size: rem-calc(14);
  line-height: 29px;
  padding: 0 14px;

  .lt-ie8 & {
    height: 33px;
  }

  &,
  .lt-ie8 &[type="submit"],
  .lt-ie8 &:hover {
    height: 35px;
  }
}

%button-small {
  @include button-small();
}

%button-med-large {
  border-width: 2px;
  border-radius: 4px;
  font-size: $text-size-large;
  padding: 0 24px;
}

%button-medium {
  @extend %button-med-large;
  line-height: normal;
  min-height: 43px;
  max-height: 50px;
  height: 50px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .lt-ie8 & {
    height: 43px;
  }
}

%button-large {
  @extend %button-med-large;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include breakpoint(medium down) {
    height: 43px;
  }
  .lt-ie8 & {
    height: 55px;
  }
}

%button-white {
  border-color: #fff;
  color: #fff;
  &:hover,
  &:focus {
    background-color: #fff;
    color: brand-color(primary-blue);
  }
}

%button-grey-border {
  border-color: brand-color(grey-light);
  color: brand-color(primary-blue);
  &:hover,
  &:focus {
    background-color: brand-color(mute-blue);
    color: brand-color(primary-blue);
  }
}

%button-light-blue-border {
  border-color: brand-color(primary-light-blue);
  color: brand-color(primary-blue);
  &:hover,
  &:focus {
    background-color: brand-color(primary-light-blue);
    color: #fff !important;
    i {
      color: #fff !important;
    }
  }
  &.filled {
    background-color: #fff;
    color: brand-color(primary-light-blue);

    &:hover:not([disabled]) {
      background-color: brand-color(mute-blue);
    }
  }
}

%button-blue {
  border-color: lighten(brand-color(primary-blue), 2%);
  &.filled {
    background-color: brand-color(primary-blue);
    color: #fff;
    border-color: lighten(brand-color(primary-blue), 2%);
    &:hover:not([disabled]) {
      background-color: brand-color(primary-dark);
      border-color: brand-color(primary-dark);
    }

    &:active {
      // box-shadow: inset 0 0 2px rgba(0,0,0, .20);
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

%button-light-blue {
  border-color: lighten(brand-color(primary-light-blue), 2%);
  &.filled {
    background-color: brand-color(primary-light-blue);
    color: #fff;
    border-color: lighten(brand-color(primary-light-blue), 2%);
    &:hover:not([disabled]) {
      background-color: brand-color(primary-light-blue-hover);
      border-color: brand-color(primary-light-blue-hover);
    }

    &:active {
      // box-shadow: inset 0 0 2px rgba(0,0,0, .20);
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  &.translucent {
    background-color: rgba(4, 170, 222, 0.9);
    border-color: rgba(4, 170, 222, 0.9);
    &:hover:not([disabled]) {
      border-color: rgba(0, 140, 186, 0.9);
      background-color: rgba(0, 140, 186, 0.9);
    }
  }
}

%button-green {
  border-color: lighten(brand-color(primary-green-dark), 2%);
  
  &.filled {
    background-color: brand-color(primary-green-dark);
    color: #fff;
    border-color: lighten(brand-color(primary-green-dark), 2%);
    i {
      color: inherit !important;
    }
    
    &:hover:not([disabled]) {
      background-color: darken(brand-color(primary-green-dark), 2%);
      border-color: darken(brand-color(primary-green-dark), 2%);
    }

    &:active {
      // box-shadow: inset 0 0 2px rgba(0,0,0, .20);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
