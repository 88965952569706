aside {
  &.SideBar {
    background-color: #fff;
    font-style: normal;
    flex-shrink: 0;
    border: 1px solid brand-color(grey-light);
    padding: 20px 20px 10px;
    border-bottom: $light-blue-border;
    @include breakpoint(medium down) {
      margin-top: 10px;
      margin-left: 35px;
      margin-right: 35px;
    }
    .sidebar-inner {
      padding-bottom: 20px;
      .sidebar-inner-title {
        font-size: $text-size-large-lh;
        line-height: $title-line-height;
        font-weight: 700;
        margin-bottom: 10px;
      }
      &.light-blue,
      &.blue,
      &.green {
        .button {
          margin: 0 auto;
          @extend .large;
          @extend .filled;
          @extend .full-width;
        }
      }
      &.light-blue {
        .sidebar-inner-title {
          color: brand-color(primary-light-blue);
        }
        .button {
          @extend .light-blue.filled;
        }
      }
      &.blue {
        .sidebar-inner-title {
          color: brand-color(primary-blue);
        }
        .button {
          @extend .blue.filled;
        }
      }
      &.green {
        .sidebar-inner-title {
          color: brand-color(primary-green-dark);
        }
        .button {
          @extend .green.filled;
        }
      }
    }
  }
}
