//Font Variables
$icomoon-font-family: "DBI-Icomoon" !default;
$icomoon-font-path: "../fonts/DBI-Icomoon" !default;

$icon-play-btn: "\e910";
$icon-print: "\e911";
$icon-home-success: "\e90b";
$icon-home-search: "\e90f";
$icon-phone: "\e900";
$icon-home: "\e908";
$icon-building: "\e909";
$icon-hamburger: "\e90e";
$icon-alert-close: "\e90c";
$icon-alert: "\e90d";
$icon-lock: "\e901";
$icon-search: "\e90a";
$icon-close: "\e902";
$icon-arrow-right: "\e903";
$icon-arrow-left: "\e904";
$icon-arrow-up: "\e905";
$icon-arrow-down: "\e906";
$icon-help: "\e907";

$varela-font-family: "Varela Round";
$varela-font-file: "VarelaRound-Regular-webfont";
$varela-font-path: "../fonts/VarelaRound" !default;
$lato-font-path: "../fonts/Lato" !default;

@font-face {
  font-family: '#{$varela-font-family}';
  src: url("#{$varela-font-path}/#{$varela-font-file}.eot");
  src: url("#{$varela-font-path}/#{$varela-font-file}.eot?#iefix")
      format("embedded-opentype"),
    url("#{$varela-font-path}/#{$varela-font-file}.woff") format("woff"),
    url("#{$varela-font-path}/#{$varela-font-file}.ttf") format("truetype"),
    url("#{$varela-font-path}/#{$varela-font-file}.svg#varela_roundregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/Lato-Regular.eot');
  src: url('#{$lato-font-path}/Lato-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$lato-font-path}/Lato-Regular.woff2') format('woff2'),
      url('#{$lato-font-path}/Lato-Regular.woff') format('woff'),
      url('#{$lato-font-path}/Lato-Regular.ttf') format('truetype'),
      url('#{$lato-font-path}/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


//Icons - Icomoon 
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?sdvqje');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?sdvqje#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?sdvqje') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?sdvqje') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?sdvqje##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play-btn {
  &:before {
    content: $icon-play-btn; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-home-success {
  &:before {
    content: $icon-home-success; 
  }
}
.icon-home-search {
  &:before {
    content: $icon-home-search; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-building {
  &:before {
    content: $icon-building; 
  }
}
.icon-hamburger {
  &:before {
    content: $icon-hamburger; 
  }
}
.icon-alert-close {
  &:before {
    content: $icon-alert-close; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}