.PageTitle {
  background-color: brand-color(grey-lighter);
  min-height: 100%;
  margin-top: 0;
  .page-title-container {
    padding-top: 25px;
    padding-bottom:25px;
    .page-title-container-wrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      //margin-bottom: 20px;

      .page-title {
        font-size: $text-size-xxlarge;
        font-weight: 700;
        color: brand-color(primary-blue);
        padding-left: 10px;
        letter-spacing: 1px;
        margin-bottom: 0;
        @include breakpoint(small down) {
          font-size:  rem-calc(30);
        }
      }
      .round-icon {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(small down) {
          width: 50px;
          height: 50px;
        }
        &.green {
          background-color: brand-color(primary-green-dark);
        }
        &.light-blue {
          background-color: brand-color(primary-light-blue);
        }
        &.blue {
          background-color: brand-color(primary-blue);
        }
        i {
          font-size: rem-calc(57);
          color: #fff;
          @include breakpoint(small down) {
            font-size: rem-calc(35);
          }
        }
      }
    }
  }
}
