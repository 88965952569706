.StepByStepGuide {
  .Accordion {
    .accordion-list-wrapper {
      padding: rem-calc(20);
      @media screen and (min-width: 640px) {
        padding: rem-calc(30);
      }
      .accordion-item {
        display: flex;
        &:last-child {
          padding-bottom: 0;
          .accordion-item-rhs {
            margin-bottom: 0;
          }
          .vertical-line {
            display: none;
          }
        }
        &.expanded,
        &.on-hover {
          // when accordion title is on hover or accordion is expanded
          .accordion-item-lhs {
            .step-number {
              background: brand-color("primary");
            }
            .vertical-line {
              border-color: brand-color("primary");
            }
          }
          .accordion-item-rhs {
            .accordion-item-hdr {
              .hdr-title .heading-h3,
              .hdr-title .icon,
              .hdr-subtitle {
                color: brand-color("primary");
              }
            }
          }
        }
        &.expanded {
          .hdr-title .icon {
            transform: rotate(180deg);
          }
        }
        .accordion-item-lhs {
          display: flex;
          flex-direction: column;
          align-items: center;
          .step-number {
            background: brand-color("primary-light-blue");
            width: 40px;
            height: 40px;
            border-radius: 40px;
            line-height: 40px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .vertical-line {
            height: 100%;
            border-left: 2px dotted brand-color("primary");
          }
        }
        .accordion-item-rhs {
          margin-left: rem-calc(20);
          margin-bottom: rem-calc(30);
          @media screen and (min-width: 640px) {
            width: 768px;
            margin-left: rem-calc(25);
            margin-bottom: rem-calc(50);
          }
          .accordion-item-hdr {
            color: brand-color("primary-light-blue");
            text-decoration: none;
            width: 100%;
            &:hover {
              cursor: pointer;
              color: brand-color("primary");
            }
            .hdr-title {
              color: inherit;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: rem-calc(10);
              .heading-h3 {
                color: inherit;
                margin-bottom: rem-calc(0);
                margin-right: rem-calc(20);
                text-align: left;
                @include h3();
                line-height: 1;
              }
            }
            .hdr-subtitle {
              color: inherit;
              margin-right: rem-calc(36);
              text-align: left;
              line-height: 1.2;
            }
            .icon-arrow-down {
              color: inherit;
            }
          }
          .accordion-item-content {
            padding: rem-calc(20) 0;
            :last-child {
              margin-bottom: 0;
            }
            // ul styles
          }
        }
      }
    }
  }
}

// .content {
//   .main-article.StepByStepGuide {
//     ul {
//       list-style-type: disc;
//       margin-left: rem-calc(10);
//       margin-right: rem-calc(10);
//       // margin-bottom: 1rem;
//       margin-top: 0;
//       padding-left: rem-calc(20);
//       li {
//         padding-left: 0;
//         &::before {
//           background-color: transparent;
//         }
//       }
//     }
//   }
// }
