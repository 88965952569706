.builders-search {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    [type=checkbox] {
        float: left;
        margin: rem-calc(0 12 0 20);
        appearance: none;
        border: 2px solid #ffffff;
        outline: 1px solid #aab5c6;
        border-radius: rem-calc(4);
        background-color: #ffffff;
        transition: all 250ms ease-out;
        height: rem-calc(20);
        width: rem-calc(20);
        background-image: url('/themes/DBI/img/checkbox-tick.png');
        background-size: rem-calc(13 9);
        background-repeat: no-repeat;
        background-position: center;
        &:hover {
            background-color: #eee;
        }
        &:checked {
            background-color: brand-color(primary-light-blue);
            &:hover {
                background-color: brand-color(primary-light-blue);
            }
        }
    }
    .read-agree {
        display: block;
        margin-bottom: rem-calc(32);
        &:after {
            content: '';
            display: table;
            clear: both;
        }

        .agree-message {
            float: left;
            width: calc(100% - #{rem-calc(52)});
            font-weight: 500;
            font-size: rem-calc(14);
            a {
                color: brand-color(primary-light-blue);
                position: relative;
                text-decoration: underline;
                &:hover {
                    color: brand-color(primary-blue-hover);  
                }
            }
        }
    }
    &.step-one {
        .button {
            float: right;
        }
    }
    &.step-two {
        .disclaimer {
            margin-bottom: rem-calc(30);
            @include breakpoint(medium up) {
                margin-bottom: rem-calc(38);
            }
        }
        .button {
            float: right;
        }
    }
    &.step-three {
        .button {
            &.search-again {
                float: right;
                clear: both;

                @include breakpoint(medium dowm) {
                    width:100%;
                    margin-bottom:25px;
                }
                @include breakpoint(medium up) {
                    float: left;
                    clear: none;
                    width:auto;
                }
                // &:hover {
                //     background-color: brand-color(primary-light-blue);
                //     background-position: #{rem-calc(19)} center;
                //     @include breakpoint(medium up) {
                //         background-position: #{rem-calc(16)} center;
                //     }
                // }
            }
            &.print-results {
                float: right;
                clear: both;
                
                @include breakpoint(medium dowm) {
                    width:100%;
                    margin-bottom:25px;
                }
          
                @include breakpoint(medium up) {
                    clear: none;
                    width:auto;
                }
            }
        }
        .disclaimer {
            margin-bottom: rem-calc(26);
            @include breakpoint(medium up) {
                margin-bottom: rem-calc(36);
            }
        }
    }
    .row.row {
        margin: 0;
    }
    .scfCaptchTop {
        .scfCaptchaLabel {
            display: none;
        }
        .scfCaptchaLimitGeneralPanel {
            table {
                width: auto;
                margin-bottom: rem-calc(8);
                tr {
                    border: 0;
                }
                td {
                    background: transparent;
                    padding: rem-calc(0 22 0 0);
                    border: 0;
                }
            }
        }
        + div {
            margin-bottom: rem-calc(20);
            .scfCaptchaLabel {
                display: none;
            }
        }
    }
    .builder-search-input {
        appearance: none;
        border: 2px solid brand-color(primary-blue);
        width: 100%;
        font-size: rem-calc(20);
        line-height: rem-calc(26);
        padding: rem-calc(10 54 10 14);
        border-radius: rem-calc(6);
        margin-bottom: rem-calc(30);
        background-image: url('/themes/DBI/img/builder-search-icon.png');
        background-repeat: no-repeat;
        background-position: right #{rem-calc(13)} center;
        height: auto;
        @include breakpoint(medium up) {
            font-size: rem-calc(22);
            line-height: rem-calc(28);
        }

        + .row {
            margin-top: rem-calc(-10);
        }
    }
   
    .table-wrap {
        width: 100%;
        max-width: 100%;
        overflow-x:auto;
        margin-top: rem-calc(26);
        margin-bottom: rem-calc(12);
        @include breakpoint(medium up) {
            margin-top: rem-calc(30);
            margin-bottom: rem-calc(24);
        }
    }
    table {
        border: 0;
        tr {
            background: transparent;
            border-bottom: 1px solid brand-color(primary-light-blue);
            &:first-child {
                border-top: 1px solid brand-color(primary-light-blue);
            }
            th {
                font-weight: bold;
                color: brand-color(primary-light-blue);
                font-size: rem-calc(15);
                width: rem-calc(110);
                padding: rem-calc(15 14);
                @include breakpoint(medium up) {
                    font-size: rem-calc(17);
                    padding: rem-calc(19 25);
                    width: rem-calc(140);
                }
            }
            td {
                background-color: #f3f6fa;
                padding: rem-calc(15 26);
                font-weight: bold;
                font-size: rem-calc(15);
                color: brand-color(primary-blue);
                img {
                    vertical-align: middle;
                    margin-left: rem-calc(13);
                }
                @include breakpoint(medium up) {
                    font-size: rem-calc(17);
                    padding: rem-calc(19 36);
                }
                &.current {
                    background-color: #f0f6e9;
                    padding: rem-calc(15 26 12);
                    img {
                        margin-bottom: rem-calc(3);
                    }
                    @include breakpoint(medium up) {
                        padding: rem-calc(19 36 16);
                    }
                }
            }
        }
    }
}

.builders-search-progress {
    width: 50%;
    display: block;
    list-style: none;
    padding: 0;
    counter-reset: progress;
    position: relative;
    margin:0 auto;
    margin-bottom:25px;
    &:before {
        content: '';
        width: 66.6666%;
        height: 1px;
        background-color: brand-color(primary-blue);
        position: absolute;
        top: rem-calc(15);
        margin-top: -0.5px;
        left: 16.6666%;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    li {
        width: 33.3333%;
        float: left;
        font-family: 'Varela Round',Lato,sans-serif;
        font-size: rem-calc(16);
        color: #9cadc1;
        text-align: center;
        padding: rem-calc(36 0 0 0);
        position: relative;
        line-height: rem-calc(32);

        &:before {
            counter-increment: progress;
            font-family: 'Varela Round',Lato,sans-serif;
            content: counter(progress);
            position: absolute;
            background-color: #ffffff;
            top: 0;
            left: 50%;
            margin-left: rem-calc(-16);
            width: rem-calc(32);
            height: rem-calc(32);
            border-radius: rem-calc(20);
            font-size: rem-calc(22);
            line-height: inherit;
            color: #9cadc1;
            border: 1px solid brand-color(primary-blue);
        }
    }
    &.one {
        li:first-child {
            color: brand-color(primary-blue);
            &:before {
                background-color: brand-color(primary-light-blue);
                border-color: brand-color(primary-light-blue);
                color: #ffffff;
            }
        }
    }
    &.two {
        li:first-child {
            color: brand-color(primary-blue);
            &:before {
                background-color: brand-color(primary-blue);
                border-color: brand-color(primary-blue);
                color: #ffffff;
            }
        }
        li:nth-child(2) {
            color: brand-color(primary-blue);
            &:before {
                background-color: brand-color(primary-light-blue);
                border-color: brand-color(primary-light-blue);
                color: #ffffff;
            }
        }
    }
    &.three {
        li {
            color: brand-color(primary-blue);
            &:before {
                background-color: brand-color(primary-blue);
                border-color: brand-color(primary-blue);
                color: #ffffff;
            }
        }
        li:last-child {
            color: brand-color(primary-blue);
            &:before {
                background-color: brand-color(primary-light-blue);
                border-color: brand-color(primary-light-blue);
                color: #ffffff;
            }
        }
    }
}
