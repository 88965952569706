.PrimaryActions {
  background: #fff;
  .primary-actions-wrapper {
    .primary-actions-title {
      margin: 0 auto;
      h2 {
        text-align: center;
        color: brand-color(primary-green-dark);
        font-size: $text-size-xlarge;
        font-weight: 700;
        margin-bottom: 25px;
      }
    }
    .btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      @include breakpoint(small down) {
        flex-direction: column;
      }
      .button {
        margin-right: 25px;
        margin-bottom: 0px;
        @include breakpoint(small down) {
          margin-right: 0px;
          margin-bottom: 25px;
          &:last-child {
            margin-right: 0;
            margin-bottom: 0px;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        i {
          color: brand-color(primary-light-blue);
        }
        
      }
    }
  }
}
