%text-input {
  border: 1px solid brand-color(grey);
  color: brand-color(grey-dark);
  font-size: 16px;
  background: rgba(#fff, 0.5);
  box-shadow: none;
  letter-spacing: 0px;
  height: 52px;
  padding: 0px 12px;
  margin: 0px;
  border-radius: 5px;
  &::placeholder {
    color: brand-color(grey);
    opacity: 0.5;
  }

  .error {
    border-color: brand-color(status-red);
  }

  &:focus {
    border-color: brand-color(grey-dark);
  }

  &[disabled],
  .disabled & {
    background: rgba(brand-color(grey-light), 0.25);
  }
  .error & {
    border-color: brand-color(status-red);
  }
}

input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="datetime"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
  @extend %text-input;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  padding-top: 6px;
  padding-bottom: 6px;
  resize: none;
  width: 100%;
  box-sizing: border-box;
  height: 104px;
}

select {
  border: 1px solid brand-color(grey);
  color: brand-color(grey-dark);
  font-size: 16px;
  background: rgba(#fff, 0.5);
  box-shadow: none;
  letter-spacing: 0px;
  padding: 0px 30px 0px 12px;
  margin: 0px;
  cursor: pointer;
  height: 52px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-position-x: calc(100% - 10px);
  background-position-y: 12px;
  background-repeat: no-repeat;
  background-size: 27px;
  appearance: none;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  &:focus {
    border: 1px solid brand-color(grey-dark);
    box-shadow: none;
    outline: none;
  }

  &[disabled],
  .disabled & {
    background: rgba(brand-color(grey-light), 0.25);
  }
}

select[size] {
  height: auto;
  background-image: none;
  padding: 10px 12px;
  option {
    padding: 6px 0px;
  }
}

.form-field-wrapper {
  margin-bottom: 24px;
  width: 100%;

  label.field-label {
    color: brand-color(grey-dark);
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
    display: block;
  }

  [class^="field-validation-"] {
    //@include font_ProximaNovaAltBold();
    display: block;
    margin-top: 7px;
    font-size: 13px;
    line-height: 16px;
    color: brand-color(grey);
  }
  .field-validation-error {
    color: brand-color(status-red);
  }

  .chbx,
  .rb {
    cursor: pointer;
    margin-bottom: 9px;
    line-height: normal;
    &:after {
      display: block;
      content: "";
      clear: both;
    }

    input {
      opacity: 0;
    }
    span {
      display: inline-block;
      padding-left: 28px;
      position: relative;
      color: brand-color(grey-dark);
      font-size: 16px;
      min-height: 16px;
      cursor: pointer;

      &:before {
        display: block;
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 16px;
        height: 16px;
        border: 1px solid brand-color(grey-dark);
      }

      &:after {
        display: none;
        content: "";
        position: absolute;
        top: 5.5px;
        left: 5.8px;
        width: 11px;
        height: 11px;
      }

      &:hover {
        &:before {
          border-color: brand-color(primary);
        }
      }
    }
  }
  .chbx {
    input:checked + span {
      &:after {
        width: 13px;
        height: 13px;
        display: block;
        top: 0.1rem;
        left: 0.1rem;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 18.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' enable-background='new 0 0 512 512' xml:space='preserve' fill='%230e76a8'%3E%3Cpolygon points='437.3,30 202.7,339.3 64,200.7 0,264.7 213.3,478 512,94 ' fill='%230e76a8'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .rb {
    span {
      &:before {
        border-radius: 11px;
      }

      &:after {
        border-radius: 7px;
        top: 0.325rem;
        left: 0.325rem;
        width: 6.5px;
        height: 6.5px;
      }
      &:hover {
        &:after {
          display: block;
          background: #e8e8e8;
        }
      }
    }
    input:checked + span {
      &:after {
        display: block;
        background-color: #0e76a8;
      }
    }
  }

  &.error {
    label.field-label {
      color: brand-color(grey);
    }
  }
  &.disabled {
    label.field-label {
      opacity: 0.5;
    }
  }
  &.form-field-type-SingleLineText {
  }
  &.form-field-type-MultipleLineText {
  }
  &.form-field-type-Number {
  }
  &.form-field-type-Email {
  }
  &.form-field-type-Telephone {
  }
  &.form-field-type-Checkbox {
  }
  &.form-field-type-DatePicker {
  }
  &.form-field-type-DropdownList {
  }
  &.form-field-type-ListBox {
  }
  &.form-field-type-CheckboxList {
  }
  &.form-field-type-RadioButtonList {
  }
  &.form-field-type-Recaptcha {
  }
  &.form-field-type-FileUpload {
    input[type="file"] {
      margin-top: 10px;
    }
    label.field-label {
      margin-bottom: 6px;
    }
  }

  &.form-field-type-Password {
  }

  &.form-field-type-PasswordConfirmation {
    .field-label-confirm {
      margin-top: 12px;
    }
  }

  &.inline {
    .items {
      display: flex;
      flex-direction: row;
      .rb,
      .chbx {
        margin-right: 16px;
      }
    }
  }
}

form {
  input[type="submit"] {
    @extend .button;
    @extend %button-medium;
  }
  .form-row {
    display: flex;
    flex-direction: column;
    @include breakpoint(large) {
      flex-direction: row;
      justify-content: space-between;
      &.half-width {
        width: calc(50% - (15px / 2));
      }
      &.full-width {
        width: 100% !important;
      }
    }
    .form-field-wrapper {
      @include breakpoint(large) {
        margin-right: 15px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .form-section-title {
    padding: 22px 0 12px;
  }

  .fxt-captcha {
    + .field-validation-valid {
      display: block;
      margin-bottom: 24px;
    }
    + .field-validation-error {
      display: block;
      margin-top: 4px;
      margin-bottom: 24px;
      font-size: 11px;
      line-height: 16px;
      color: brand-color(status-red);
    }
  }
}

// Custom file inputs
.custom-file-input {
  border-radius: 2px;
  box-shadow: inset 0 1px 3px rgba(9, 42, 75, 0.25);
  box-sizing: border-box;
  color: brand-color(grey);
  float: left;
  //font: normal 16px/22px $lato-font-set;
  height: 52px;
  padding: 13px 140px 13px 14px;
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  span {
    color: brand-color(grey);
    display: block;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }

  input[type="file"] {
    cursor: pointer;
    height: 52px;
    margin-top: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0px;
    width: 127px;
    z-index: 1;
  }

  button {
    background-color: brand-color(primary-light-blue);
    border: none;
    border-left: 1px solid brand-color(grey-light);
    color: brand-color(grey);
    font-size: 16px;
    height: 52px;
    line-height: 48px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 127px;
  }

  &:before {
    border: 1px solid brand-color(grey-light);
    border-radius: 2px;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

label + input[type="file"] {
  margin-top: 10px;
}
