#sntch_popup{
  font-family: Lato!important;
  width:300px!important;
}
#sntch_button{
  box-shadow: none!important;
  background-color:transparent!important;
}

.chat__bot-name{
  font-family: 'Varela Round', sans-serif!important;
}
