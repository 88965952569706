footer {
  background-color: brand-color(primary-blue);
  color: #fff;
  font-size: rem-calc(16);
  line-height: 18px;
  margin-top: 2rem;
  @include breakpoint(medium) {
    padding: 20px 0px;
  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li {
      list-style: none;
    }
  }

  p {
    color: #fff;
    font-size: inherit;
    margin-bottom: 3px;
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  #footer-links {
    @include breakpoint(medium down) {
      margin: 0 auto;
    }
    li {
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0px;
      }
      a {
      }
    }
  }

  #company-contact {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 25px;
      flex-wrap: wrap;
      @include breakpoint(medium down) {
        justify-content: center;
      }

      li {
        margin-right: 15px;
        @include breakpoint(medium down) {
          //flex: 1 0 50%;
          text-align: center;
          &.phone-number {
            flex: 0 1 100%;
            text-align: center;
            margin-bottom: 10px;
            margin-right: 0;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: #fff;
          text-decoration: underline;
          &:hover {
            color: brand-color(primary-blue-hover);
          }
          &.phone {
            text-decoration: none;
            @include breakpoint(medium down) {
              margin-right: 0px;
            }
          }
        }
      }
    }
    ul.footer-social-links {
      margin-top: 30px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: nowrap;
      gap: 15px;
      @include breakpoint(medium down) {
        justify-content: center;
      }

      li {
        margin-right: 5px;
        flex: initial;
        &:last-child {
          margin-right: 0px;
        }
        a {
          display: block;
          img {
            width: 75%;
            height: auto;
          }
        }
      }
    }
  }

  #state-gov-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include breakpoint(small down) {
      flex-direction: column;
      align-items: unset;
    }
    #state-gov-links {
      ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        @include breakpoint(small down) {
          flex-direction: column;
          align-items: center;
        }
        margin-top: 25px;
        li {
          a {
            color: #fff;
            margin-right: 10px;
            text-decoration: none;
            &:hover {
              color: brand-color(primary-blue-hover);
            }
          }
        }
      }
    }
    #state-gov-logo {
      justify-content: flex-end;
      display: flex;
      @include breakpoint(small down) {
        padding-top: 20px;
        align-items: flex-end;
      }
      img {
      }
    }
  }

  .disclaimer {
    padding-top: 25px;
    font-size: rem-calc(12);
    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }
}
