.SideNav {
  box-shadow: $widget-shadow;
  height: 100%;
  width: 450px;
  position: fixed;
  z-index: layer(sidenav);
  top: 0;
  right: -450px;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 75px 55px;

  @include breakpoint(small down) {
    width: 100%;
    right: -100%;
  }
  &.open {
    right: 0px;
  }

  .close-btn {
    transition: 0.5s;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .header-menu {
    ul {
      @include unlist();
      line-height: inherit;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .header-menu-button {
      position: relative;
      min-height: 55px;
      max-height: 55px;
      border-radius: $border-radius;
      &.button-help-centre {
        padding: 0 35px 0 65px;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-right-color: transparent;
        border-right: 0;
        outline-color: brand-color(primary-blue);
        display: flex;
        flex-direction: row;
        i.icon-help {
          font-size: $button-icon-size-lg;
          position: absolute;
          left: 15px;
          top: 11px;
        }
      }
      &.button-side-menu {
        padding: 0 50px 0 20px;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-left-color: transparent !important;
        border-left: 0;
        outline-color: brand-color(grey-light);
        i.icon-hamburger {
          font-size: $button-icon-size-md;
          position: absolute;
          right: 18px;
          top: 15px;
        }
        i.icon-close {
          font-size: $button-icon-size-sm;
          position: absolute;
          right: 15px;
          top: 16px;
        }
      }
    }
  }

  .sidenav-content {
    padding-top: 50px;
    overflow: visible;
    .sidenav-content-title {
      padding: 5px 0;
      font-size: $text-size-larger-lh;
      line-height: $header-line-height;
      font-weight: 700;

      &.blue {
        color: brand-color(primary-blue);
      }

      &.green {
        color: brand-color(primary-green-dark);
      }

      &.light-blue {
        color: brand-color(primary-light-blue);
      }
    }
    .sidenav-content-section {
      ul {
        @include unlist();
        padding-top: 10px;
        &.icon-links {
          li {
            a {
              font-size: $text-size-large;
              color: brand-color(primary-blue);
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              i.icon {
                color: brand-color(primary-light-blue);
                font-size:  rem-calc(30);  
                width: 40px;
                text-align: center;
                margin-right: 10px;
                margin-left: -10px;
              }
            }
          }
        }
        &.secondary-links {
          li {
            a {
              text-decoration: none;
              font-size: $text-size-large;
              color: brand-color(primary-dark);
            }
          }
        }
        li {
          a {
            line-height: $list-line-height;
            color: brand-color(primary-blue);
            text-decoration: underline;
            font-weight: bold;
            &:hover {
              color: brand-color(primary-blue-hover);
            }
          }
        }
      }
    }
    .divider {
      hr {
        margin-right: -55px;
        margin-left: -55px;
        border-bottom: 1px solid brand-color(grey-lightest);
      }
    }
  }
}
.sidenav-blocker {
  visibility: hidden;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: layer(sidenav-blocker);
  left: 0;
  top: 0;
  background: transparent;
  transition: all 0.5s ease-in-out;
  &.visible {
    background: brand-color(grey-dark);
    opacity: 0.6;
    visibility: visible;
    overflow: visible;
  }
}
.SideNav::-webkit-scrollbar {
  width: 8px;
}
.SideNav::-webkit-scrollbar-track {
  background: brand-color(mute-blue);
  margin-top: 1px;
}
.SideNav::-webkit-scrollbar-thumb {
  background-color: brand-color(grey-light);
  border-radius: 5px;
  border: 1px solid #fff;
}
