.HomeBanner {
  background: brand-color(primary-blue);
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-top: 0;
  &:after {
    content: "";
    position: absolute;
    height: 350px;
    background: white;
    transform: rotate(-6deg);
    height: 100%;
    z-index: layer(homebanner);
    display: block;
    left: -50%;
    right: -10%;
    bottom: -250px;
    @include breakpoint(small down) {
      right: -75%;
      bottom: -550px;
      transform: rotate(-8deg);
    }
    @include breakpoint(small) {
      bottom: -475px;
    }
    @include breakpoint(medium) {
      right: -20%;
      bottom: -450px;
    }
  }
  .home-banner-container {
    padding-top: 30px;
    .home-banner-title-wrap {
      max-width: 768px;
      margin: 0 auto;
      .home-banner-title h1 {
        text-align: center;
        color: #fff;
        @include h1();
      }
    }
    .home-banner-user-segments {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 75px;

      @include breakpoint(small down) {
        flex-direction: column;
        justify-content: center;
        margin-top: 5px;
        padding-left: 0;
        padding-right: 0;
      }
      .user-segment {
        position: relative;
        width: 48%;
        flex-basis: 48%;
        background: white;
        min-height: 350px;
        border-radius: 5px;
        box-shadow: $widget-shadow;
        z-index: layer(user-segment);
        @include breakpoint(small down) {
          width: 100%;
          margin-top: 75px;
        }

        &.light-blue,
        &.blue,
        &.green {
          .button {
            margin: 0 auto;
            @extend .large;
            @extend .filled;
          }
        }
        &.light-blue {
          border-bottom: 7px solid primary-color(light-blue);
          .user-segment-inner {
            .round-icon {
              background-color: primary-color(light-blue);
            }
            .user-segment-title {
              color: primary-color(light-blue);
            }
            .button {
              @extend .light-blue;
            }
          }
        }
        &.blue {
          border-bottom: 7px solid primary-color(blue);
          .user-segment-inner {
            .round-icon {
              background-color: primary-color(blue);
            }
            .user-segment-title {
              color: primary-color(blue);
            }
            .button {
              @extend .blue;
            }
          }
        }
        &.green {
          border-bottom: 7px solid primary-color(green);
          .user-segment-inner {
            .round-icon {
              background-color: primary-color(green);
            }
            .user-segment-title {
              color: primary-color(green);
            }
            .button {
              @extend .green;
            }
          }
        }
        .user-segment-inner {
          margin: 0 auto;
          width: 100%;
          padding: 25px;
          padding-top: 60px;
          text-align: center;
          .user-segment-title {
            font-size: $text-size-xlarge;
            line-height: $header-line-height;
            font-weight: 700;
          }
          p {
          }
          ul {
            @extend %ul;
            padding: 25px 0;
            li {
              a {
                text-decoration: underline;
              }
            }
          }
          .button {
            width: 75%;
            @include breakpoint(medium down) {
              width: 100%;
            }
          }
          .round-icon {
            position: absolute;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            top: -45px;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateX(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: rem-calc(60);
              color: #fff;
            }
          }
        }
      }
    }
  }
}
