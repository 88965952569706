.search-predictive-results {
    position: absolute;
    z-index: layer(predictive-search);
    top: 45px;  // just below the textbox.
    left: 0px;
    background: #fff;
    border: 1px solid #ccc;
    padding: 20px 20px 10px;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
    width: 100%;
    font-size: rem-calc(14);  
    h4 {
        margin: 0px 0px 15px 0px;
    }
    ul {
        @include unlist();
        
        li { 
            margin: 0px 0px 10px 0px;
            a {
                
            }
        }
    }
}