#carousel-container {
  position: relative;
  
  .carousel {
    // all carousel types
    .bg-image {
      .img {
        // the actual image (if any)
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: layer(carousel);
        max-width: 100vw !important;
        max-height: 100vh !important;
        top: 0;
      }
    }
    .heading,
    .blurb {
      // for T1
      margin: 0px;
      padding: 0px;
    }
    .text {
      .heading,
      .blurb {
        // for T2 and Campaign
        margin: 0px;
        padding: 0px;
      }
    }
  }

  .carousel-t1 {
    .bg-image {
      //   height: 230px;
      //   @include breakpoint(large) {
      //     height: 280px;
      //   }
    }

    .heading {
      @extend h1;
      padding-top: 30px;
      padding-bottom: 20px;
      text-align: center;
      @include breakpoint(large) {
        background: rgba(255, 255, 255, 0.8);
        padding: 25px 68px 25px 68px;
        text-align: left;
        max-width: 640px;
        // lift it up
        margin-top: -226px;
      }
    }

    .blurb {
      padding-bottom: 30px;
      text-align: center;
      @include breakpoint(large) {
        text-align: left;
        font-size: rem-calc(20);
        padding-top: 30px;
        padding-bottom: 40px;
      }
    }

    &.has-panel {
      .blurb {
        @include breakpoint(large) {
          margin-right: 392px;
          padding-right: 143px;
        }
      }
    }

    .panel-container {
      padding-bottom: 20px;

      @include breakpoint(large) {
        position: absolute;
        top: 200px;
        width: 100%;
      }

      .grid-container {
        position: relative;

        .panel-content {
          //@extend .special-font-medium;
          font-size: rem-calc(16);

          @include breakpoint(large) {
            width: 392px;
            height: 192px;
            position: absolute;
            right: map-get($grid-container-padding, large) / 2;
          }

          &.theme-dark {
            // @include theme-override {
            //     color: #fff;
            // }
            //@include theme-gradient('primary');
            border-radius: 3px;
            a,
            a:visited {
              color: #fff !important;
            }
          }

          .quicklinks {
            padding: 30px 26px 40px 28px;
            ul {
              li {
                padding-bottom: 20px;
                &:last-child {
                  padding-bottom: 0px;
                }
              }
            }
          }
          ul {
            //@extend %ul;
          }
        }
      }
    }
  }

  .carousel-t2 {
    .bg-image {
      height: 35px;

      @include breakpoint(medium) {
        height: 90px;
      }
      @include breakpoint(large) {
        height: 140px;
      }
    }
    .text {
      padding: 16px 0px 40px 0px;
      //background: #fff;
      text-align: center;
      @include breakpoint(medium) {
        text-align: left;
        margin-top: -20px; // lift it up
        padding: 20px 30px 40px 20px;
        display: inline-block;

        @include breakpoint(medium) {
          max-width: 499px;
        }
      }
      .heading {
        @extend h1;
      }
    }
  }

  .bg-image {
    .img {
      //   height: 240px;
      //   @include breakpoint(large) {
      //     height: 100%;
      //   }
    }
    //height: 225px;
    // @include breakpoint(large) {
    //     height: 100%;
    // }
  }
  .text {
    .heading {
      @extend h1;
      padding-top: 30px;
      padding-bottom: 20px;
      @include breakpoint(large) {
        padding-top: 0px;
      }
    }
    .blurb {
      padding-bottom: 30px;
      @include breakpoint(large) {
      }
    }
    .btns {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      padding-bottom: 30px;

      .button,
      a {
        margin-bottom: 16px;
        i.icon-play-btn {
            font-size: $button-icon-size-lg;
        }
      }
      a:not(.btn) {
        text-align: center;
        font-size: rem-calc(16);
        position: absolute;
        bottom: 30px;
        left: 70px;
        z-index: layer(carousel);
        color: #fff;
        @include breakpoint(medium down) {
          left: 20px; 
          bottom: 10px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }

      @include breakpoint(large) {
        flex-flow: row nowrap;
        .button,
        a {
          margin-right: 16px;
          margin-bottom: 0px;
        }
      }
    }
  }

  @include breakpoint(large) {
    .text {
    }
    &.has-image {
      .text {
        background: rgba(255, 255, 255, 0.8);
      }
      &.theme-dark {
        .text {
        }
      }
    }
  }

  &.centered {
    text-align: center;
    @include breakpoint(large) {
      .btns {
        justify-content: center;
      }
      .text {
        left: 50%;
        margin-left: calc(0px - (729px / 2));
      }
    }
  }
}
.slick-initialized .slick-slide {
  display: block;
  height: 306px;
  position: relative;
  @include breakpoint(small down) {
    height: 236px;
  }
  .btns .button {
    opacity: 0;
    transition: opacity 1s ease-in;
  }
  &.slick-current.slick-active {
    .btns .button {
      opacity: 1;
      transition: opacity 1s ease-out;
    }
  }
}

.slick,
.slick-wrapper {
  width: 100%;
}

.video-carousel {
  height: 100%;
  > div:not(:first-child) {
    display: none; // hide all of them except the first
  }
  &.slick-slider {
    display: table;
    table-layout: fixed;
    width: 100%;
    > div {
      border-top: $light-blue-border;
      //box-shadow: $widget-shadow;
      border-radius: 5px;
      @include breakpoint(medium down) {
        border-radius: 0px;
      }
      &:before {
        @include breakpoint(large) {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50px;
          height: 100%;
          background: url(../img/global/banner_corner_bl_grey.png) bottom left
            no-repeat;
          background-size: contain;
          z-index: layer(carousel);
        }
      }
    }
  }

  UL.slick-dots {
    @include unlist();
    position: absolute;
    top: 220px;
    left: 20px;
    width: 100%;
    z-index: layer(carousel);
    font-size: 0px;

    // @include breakpoint(large) {
    bottom: -30px;
    left: 50px;
    top: auto;
    // }
    @include breakpoint(small down) {      
      left: 20px;

    }
    li {
      cursor: pointer;
      display: inline-block;
      text-indent: -9999px;
      width: 12px;
      height: 12px;
      background: brand-color(grey-light);
      border-radius: 50%;
      margin-right: 15px;
      &.slick-active {
        background: brand-color(primary-blue);
      }
    }
  }
}
