html,
body,
header,
main,
section,
aside,
h1,
h2,
h3,
h4,
h5,
* {
  font-family: $varela-font-family;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
a,
a:visited {
  color: brand-color(primary);
  text-decoration: underline;
}
a:hover,
a:active {
  color: brand-color(primary-blue-hover);
}

blockquote {
  border-left: 8px solid brand-color(primary-light-blue);
  color: brand-color(primary-light-blue);
  font-size: 1.0625rem;
  line-height: 1.5rem;
  padding: 0 0 0 1.875rem;
  margin: 2.5rem 0 2.5rem 3.125rem;
  width: auto;
  font-style: italic;
}

.page-wrapper {
  //push down content for sticky header
  @include breakpoint(medium) {
    padding-top: 0 !important;
  }
}

.PageTitle {
  //push down content for sticky header
  @include breakpoint(medium) {
    padding-top: 0 !important;
  }
}

.small-full {
  @include breakpoint(medium down) {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

section,
aside {
  margin: 50px 0;
}

//Utility Helpers
.background-white {
  background: #fff !important;
}

.background-primary {
  background: brand-color(primary-blue) !important;
}

.color-dark {
  color: brand-color(grey-dark) !important;
}

.color-primary {
  color: brand-color(primary-blue) !important;
}

.no-padding-left {
  padding-left: 0;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}


#skip a {
  position: fixed;
  top: 0px;
  left: 50%;
  padding: 5px 10px;
  background: #fff;
  outline: none;
  transition: transform 0.2s ease-in-out;
  transform: translate(-50%, -100%);
  z-index: 10000;
  border: 2px solid brand-color(primary-blue);
  border-top: 0px;


  &:focus {
    transform: translate(-50%, 0%);
  }
}