.whats-covered-area {
    > .row {
        padding: rem-calc(0 20);
        @media #{$medium-up} {
            position: relative;
            padding: 0;
            background: rgb(202,237,252);
            background: -moz-linear-gradient(top,  rgba(202,237,252,1) 0%, rgba(202,237,252,1) 20%, rgba(243,252,219,1) 80%, rgba(243,252,219,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(202,237,252,1) 0%,rgba(202,237,252,1) 20%,rgba(243,252,219,1) 80%,rgba(243,252,219,1) 100%);
            background-image: url('/themes/DBI/img/clouds.svg'), linear-gradient(to bottom,  rgba(202,237,252,1) 0%,rgba(202,237,252,1) 20%,rgba(243,252,219,1) 80%,rgba(243,252,219,1) 100%);

            background-size: contain, cover;
            background-position: center;
            background-repeat: no-repeat;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#caedfc', endColorstr='#f3fcdb',GradientType=0 ); /* IE6-9 */
            h1 {
                margin: 2rem 0 2rem;
            }
        }
    }
    .whats-covered-list {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
        @media #{$medium-up} {
            position: absolute;
            // top: rem-calc(144);
            left: 0;
            width: 100%;
            height: calc(100% - #{rem-calc(144)});
        }
        > li {
			> a {
				text-decoration:none !important;
			}
			padding:0 !important;
            margin-bottom: rem-calc(23);
            @media #{$medium-up} {
                width: 27.5%;
                margin-bottom: rem-calc(12);
                max-width: rem-calc(240);
                position: relative;
                > a {
                    cursor: pointer;
                    display: block;
                    padding-left: rem-calc(12);
                    padding-right: rem-calc(40);
					text-decoration:none !important;
                }
                &:hover {
                    .btn {
                        opacity: 1;
                    }
                }
            }
			&:before {
				content: "" !important;
				height: 0 !important;
			}
        }
        .list-title {
            color: #0131ab;
            font-size: rem-calc(18);
            line-height: rem-calc(20);
            padding-bottom: rem-calc(4);
            font-family: 'Varela Round',Lato,sans-serif;
            margin: 0;

        }
        .btn {
            display: none;
            @media #{$medium-up} {
                display: inline-block;
                margin-top: rem-calc(7);
                opacity: 0;
                transition: all 250ms ease-out;
                padding: rem-calc(8 15 7);
            }
        }
        .list-text {
            color: #32383d;
            font-size: rem-calc(16);
            line-height: rem-calc(28);
            font-weight: 300;
            max-width: 66.6666%;
            position: relative;
            @media #{$medium-up} {
                max-width: 100%;
                line-height: rem-calc(30);
                position: static;
            }
            * {
                font-size: rem-calc(16);
                line-height: rem-calc(18);
                color: #32383d;
                &:last-child {
                    margin-bottom: 0;
                }
                @media #{$medium-up} {
                    line-height: rem-calc(18);
                }
            }
            &:after {
                content: '>';
                position: absolute;
                top: 50%;
                right: -50%;
                font-family: 'Varela Round',Lato,sans-serif;
                color: #ffffff;
                font-size: rem-calc(20);
                width: rem-calc(28);
                height: rem-calc(28);
                border-radius: rem-calc(28);
                text-align: center;
                line-height: inherit;
                margin: rem-calc(-14 -8 0 0);
                @media #{$medium-up} {
                    content: '?';
                    transition: all 250ms ease-out;
                    line-height: inherit;
                    top: rem-calc(-4);
                    right: rem-calc(0);
                    margin: 0;
                }
            }
        }
        .covered1 {
            @media #{$medium-up} {
                float: left;
                text-align: right;
            }
            .list-text {

                &:after {
                    background-color: #00ade7;;
                    @media #{$medium-up} {
                        background-color: #073163;
                    }
                }
            }
            .btn {
                background-color: #00ade7;;
                color: #ffffff;
            }
            &:hover {
                @media #{$medium-up} {
                    .list-text {
                        &:after {
                            background-color: #00ade7;;
                        }
                    }
                }
            }
        }
        .covered2 {
            @media #{$medium-up} {
                float: right;
                clear: right;

                > a {
                    padding-left: rem-calc(40);
                    padding-right: rem-calc(12);
                }
            }
            .btn {
                background-color: #f3d60c;
                color: #ffffff;
            }
            .list-text {

                &:after {
                    background-color: #f3d60c;
                    @media #{$medium-up} {
                        background-color: #073163;
                        right: auto;
                        left: 0px;
                    }
                }
            }
            &:hover {
                @media #{$medium-up} {
                    .list-text {
                        &:after {
                            background-color: #f3d60c;
                        }
                    }
                }
            }
        }
        .covered3 {
            @media #{$medium-up} {
                float: left;
                clear: both;
                text-align: right;
            }
            .btn {
                background-color: #a9dc1b;
                color: #ffffff;
            }
            .list-text {

                &:after {
                    background-color: #a9dc1b;
                    @media #{$medium-up} {
                        background-color: #073163;
                    }
                }
            }
            &:hover {
                @media #{$medium-up} {
                    .list-text {
                        &:after {
                            background-color: #a9dc1b;
                        }
                    }
                }
            }
        }
        .covered4 {
            @media #{$medium-up} {
                float: right;
                > a {
                    padding-left: rem-calc(40);
                    padding-right: rem-calc(12);
                }
            }
            .btn {
                background-color: #00ade7;;
                color: #ffffff;
            }
            .list-text {

                &:after {
                    background-color: #00ade7;;
                    @media #{$medium-up} {
                        background-color: #073163;
                        right: auto;
                        left: 0px;
                    }
                }
            }
            &:hover {
                @media #{$medium-up} {
                    .list-text {
                        &:after {
                            background-color: #00ade7;;
                        }
                    }
                }
            }
        }
    }
    .house {
        display: none;
        @media #{$medium-up} {
            display: block;
            padding-top: calc(90px - (40% - 366px));
            text-align: center;
            font-size: 0;
            svg {
                .no-cssfilters & {
                    height: calc(68.75rem * 0.34153005464);
                    padding-top: 0;
                    margin-top: rem-calc(120);
                    max-height: 100%;
                    width: 100%;
                }
                * {
                    transition: all 250ms ease-out;
                }
                .additional-line {
                  opacity: 0;
                }
                .incomplete-lines {
                  opacity: 0;
                }
            }
            &.covered3hover {
                .st3, .st32 {
                    stroke: #a9dc1b;
                }
                .st22 {
                    opacity: 1;
                }
                .squares {
                    * {
                        fill: #a9dc1b;
                    }
                }
            }
            .lights {
                opacity: 0;
            }
            &.covered2hover {
                .lights {
                    opacity: 1;
                }
            }
            &.covered1hover {
              .st3, .st32, .st12 {
                  stroke: #00ade7;;
              }
                .st0, .st4, .st5 {
                    fill: transparent;
                }
                .squares * {
                  fill: #ffffff;
                }
                .chimney {
                    stroke: transparent;
                }
                .light, .door, .window-line {
                  opacity: 0;
                }
                .additional-line,
                .incomplete-lines {
                  opacity: 1;
                }
            }
            &.covered4hover {
                .st3, .st32, .st12 {
                    stroke: #00ade7;;
                }
            }
        }
        @media #{$large-up} {

        }
    }
}
