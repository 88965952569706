.Feedback-form {
    margin-top: 100px;
    * {
        font-size: 14px !important;
    }
    .form-field-wrapper {
        label {
            font-size: 14px;
        }
    }
}
.Feedback {
    padding-top: 30px;
	
    position: relative;
    &::after {
        content: "";
        border-top: 2px solid brand-color(primary-blue);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    &.Step-1 {
        margin-top: 40px;
    }
    &.Step-2 {
        padding-bottom: 30px;
    }
    &.Step-3 {
        margin-top: 40px;
    }

    .form-field-wrapper label.field-label {
        font-size: 14px;
    }

    .form-field-type-RadioButtonList {
        .items {
            margin-left: -35px;
            @include breakpoint(small only) {
                flex-direction: column;
            }
        }
        .rb {
            margin-right: 0;
            padding-top: 0;
            @include breakpoint(small only) {
                margin: 0;
            }
            input:checked + span {
                &:before {
                    color: #fff;
                    background-color: brand-color(primary-blue);
                }

                &:after {
                    display: none;
                }
            }

            span {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                width: 100px;
                text-align: center;
                line-height: 1.5;
                font-size: 14px;

                align-items: flex-start;
                width: auto;
                margin-left: 60px;

                &:before {
                    content: attr(data-val);
                    height: 25px;
                    width: 25px;
                    border-radius: 30px;
                    border-color: brand-color(primary-blue);
                    color: brand-color(primary-blue);
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: absolute;
                    left: -15px;
                    top: -4px;
                }

                &:hover {
                    &:before {
                        color: #fff;
                        background-color: brand-color(primary-blue);
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}
