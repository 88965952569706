// Font sizes
$base-font-size: rem-calc(16);

$text-size-small: rem-calc(11);
$text-size-medium: rem-calc(14);
$text-size-large: rem-calc(19);
$text-size-large-lh: rem-calc(21);
$text-size-larger: rem-calc(23);
$text-size-larger-lh: rem-calc(24);
$text-size-xlarge: rem-calc(30);
$text-size-xlarge-lh: rem-calc(36);
$text-size-xxlarge: rem-calc(44);

//Line Height
$base-line-height: 20px;

$title-line-height: 1.2;
$header-line-height: 1.6;
$list-line-height: 2.6;
$list-line-height-sm: 1.6;

//Button Icon Size
$button-icon-size-lg: 28px;
$button-icon-size-md: 22px;
$button-icon-size-sm: 18px;
$button-icon-size-xs: 14px;

@mixin h1 {
  font-size: rem-calc(36);
  font-weight:700;
  letter-spacing: -0.25px;
}
@mixin h2 {
  font-size: rem-calc(28);
  letter-spacing: -0.25px;
}
@mixin h3 {
  font-size: rem-calc(24);
  line-height: 28px;
  letter-spacing: -0.25px;
}
@mixin h4 {
  font-size: rem-calc(19);
  line-height: 18px;
  letter-spacing: 0px;
}
@mixin h5 {
  font-size: rem-calc(17);
  line-height: 17px;
  letter-spacing: 0px;
}

h1 {
  @include h1();
}
h2 {
  @include h2();
}
h3 {
  @include h3();
}
h4 {
  @include h4();
}
h5 {
  @include h5();
}

// PARAGRAPHS
p {
  font-size: $base-font-size;
  line-height: $base-line-height;
  letter-spacing: 0px;

  &.light {
    color: brand-color(grey-light);
  }

  &.medium {
    font-size: rem-calc(20);
    line-height: 33px;
  }
  &.large {
    font-size: rem-calc(22);
    line-height: 33px;
  }
  &.extra-large {
    font-size: rem-calc(24);
    line-height: 33px;
  }
  &.small {
    font-size: $text-size-medium;
  }
  &.extra-small {
    font-size: rem-calc(11);
    line-height: 16px;
  }
  @include breakpoint(large only) {
    &.large {
      font-size: rem-calc(24);
    }
    &.extra-large {
      font-size: rem-calc(28);
    }
  }
}

%ul {
  @include unlist();
}
