.ups-container {
  padding-top: 30px;
  padding-bottom: 10px;

  @include breakpoint(large) {
    padding-top: 60px;
    padding-bottom: 10px;
  }

  .ups-intro {
    text-align: center;

    @include breakpoint(large) {
      padding-bottom: 30px; // total of 50px;
    }

    .headline {
      @include h2();
      padding-bottom: 20px;
      max-width: 820px;
      margin-left: auto;
      margin-right: auto;
    }
    .blurb {
      // needs to be 20px for large screen
      margin-bottom: 0px;
      padding-bottom: 30px;
      max-width: 820px;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint(large) {
        font-size: rem-calc(20);  
        padding-bottom: 20px;
      }
    }
  }

  .ups-footer {
    text-align: center;
    padding-top: 10px; // as the .up item above it will have 20px on the bottom, for a total of 30px below
    padding-bottom: 20px; // as the .ups item wrapper has 20px on the bottom, for a total of 30px below

    @include breakpoint(large) {
      padding-top: 0px;
      padding-bottom: 50px;
    }
  }

  .ups-items {
    .grid-x {
      .cell {
        display: flex; // this will make it's content stretch to full height
      }
    }
    a {
      display: flex;
      width: 100%;
      text-decoration: none;
    }

    .ups-item {
      background: #fff;
      margin-bottom: 20px;
      border-radius: 6px;
      overflow: hidden; // to make border-radius apply to children as well
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid brand-color(grey-light);
      border-bottom: $light-blue-border;
      padding: 25px 25px 0px 25px;

      @include breakpoint(large) {
        margin-bottom: 50px;
      }

      .image {
        flex-grow: 0;
        flex-shrink: 0;
        .img {
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          padding-bottom: calc(
            (162 / 288) * 100%
          ); // 2,3,4 are the same ratio for mobile/desktop
        }
      }
      .text {
        // padding: 20px 16px 0px;
        text-align: left;
        display: block;
        //flex-basis: 100%;   // VS9-310 Button widths were 100% due to VS-248 (IE bug). This helps with IE
        flex-basis: auto; //IE 11 Height/Display Fix
        .sub-headline {
          @include h3();
          color: brand-color(primary-light-blue);
          font-weight: 700;
          padding-bottom: 7px;
        }
        .headline {
          @include h1();
          padding-bottom: 20px;
          font-weight: 700;
          color: brand-color(primary-blue);
        }
        .blurb {
          display: block;
          padding-bottom: 25px;
          margin-top: 0px;
          margin-bottom: 0px;
          color: brand-color(body);
        }
        .btns {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 10px;
          margin-top: auto; // will make it stick to bottom
          .btn,
          a {
            margin-bottom: 20px;
            @include breakpoint(large) {
              margin-bottom: 16px;
              max-width: 100%;
            }
          }
          a:not(.btn) {
            display: inline-block;
          }
        }
      }
    }
  }

  &.padded {
    margin: 30px 0px;
    @include breakpoint(large) {
      margin: 40px 0px;
    }
  }
}

.ups-1,
.ups-2,
.ups-3,
.ups-4 {
  .ups-items {
    .ups-item.theme-dark {
      .text {
        .headline {
        }
      }
    }
  }
}

@include breakpoint(medium down) {
  // i'm doing this so I don't have to re-overide them for large
  .ups-1 {
    padding-top: 20px;
    .ups-items {
      .ups-item {
        border-radius: 0px;
        // bust out to be 100% width of viewport

        //@include full-width-cell(small);

        @include breakpoint(medium only) {
          //@include full-width-cell(medium);
        }

        .image {
          .img {
            padding-bottom: calc((240 / 320) * 100%);
          }
        }
        .text {
          padding-top: 30px;
          .headline {
            // from H2
            font-size: rem-calc(28); 
            letter-spacing: -0.8px;
          }
        }
      }
    }
  }
}

  .ups-1 {
    .ups-items {
      .ups-item {
        padding: 40px 40px 60px 40px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        height: 450px;

        .image {
          width: 468px;
          flex-grow: 0;
          flex-shrink: 0;
          .img {
            padding: 0px;
            width: 100%;
            height: 100%;
          }
        }
        .text {
          flex-grow: 1;
          flex-shrink: 1;
          padding: 40px 0px 0px;
          text-align: left;
          margin-left: 40px;
          .headline {
            font-size: rem-calc(44); 
            line-height: 44px;
            letter-spacing: -1.5px;
          }
          .blurb {
          }
          .btns {
            flex-direction: row;
            flex-wrap: nowrap;
            margin-top: 0px; // so it doesn't stick to bottom
            .btn,
            a {
              margin-bottom: 20px;
              margin-right: 15px;
            }
          }
        }
      }
    }

    &.reversed {
      .ups-items {
        .ups-item {
          flex-direction: row-reverse;
          .text {
            margin-left: 0px;
            margin-right: 40px;
          }
        }
      }
    }
  }

  .ups-2 {
    .ups-items {
      .ups-item {
        .image {
          .img {
          }
        }
        .text {
          @include breakpoint(medium) {
            padding: 30px 40px 0px;
          }
          .headline {
            font-size: rem-calc(36); 
            line-height: 44px;
            letter-spacing: -1px;
          }
          .blurb {
          }
        }
      }
    }
  }

  .ups-3 {
    .ups-items {
      .ups-item {
        .image {
          .img {
          }
        }
        .text {
          .headline {
            font-size: rem-calc(24);
            line-height: 28px;
            letter-spacing: -0.67px;
          }
          .blurb {
          }
        }
      }
    }
  }

  .ups-4 {
    .ups-items {
      .ups-item {
        .image {
          .img {
          }
        }
        .text {
          .sub-headline {
            line-height: 28px;
            letter-spacing: -0.67px;
          }
          .headline {
            line-height: 1.1;
            letter-spacing: -0.67px;
          }
          .blurb {
            line-height: 20px;
            color: brand-color(body);
          }
        }
        &:hover {
          cursor: pointer;
          background: brand-color(primary-light-blue);
          color: #fff;
          .text {
            p,
            .sub-headline,
            .headline,
            .blurb {
              color: #fff;
            }
          }
        }
      }
    }
  }

