$main-navigation-item-height: 120px;
$sticky-navigation-item-height: 48px;
$mobile-menu-animation: 0.2s ease-in-out;

header {
  background-color: #fff;
  width: 100%;
  box-shadow: $widget-shadow;
  @include breakpoint(small down) {
    position:fixed;
    z-index: layer(header);
  }
  &.light-blue-strip {
    border-bottom: $light-blue-border;
  }
  .header-container {
    height: $header-height-desktop;
    position: relative;
    display: flex;
    align-items: center;

    .header-top {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      flex-shrink: 0;  
      .logo-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100px;
        @include breakpoint(medium down) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        a {
          // outline: none;
          &.logo {
            img {
              margin: 0;
              height: 100%;
              width: 160px;
              outline: none;
              @include breakpoint(small down) {
                width: 120px;
                height: auto;
              }
            }
          }
        }

        .company-title {
          color: brand-color(primary-blue);
          font-size: rem-calc(22);
          padding-left: 20px;
          padding-top: 38px;
          font-weight: bold;
          @include breakpoint(medium down) {
            font-size: rem-calc(18);
            padding-top: 10px;
            padding-left: 0px;
          }
          @include breakpoint(small down) {
            font-size: rem-calc(14);
            padding-top: 10px;
            padding-left: 0px;
          }
        }
      }

      .header-menu {
        ul {
          @include unlist();
          line-height: inherit;
          display: flex;
          flex-direction: row;
          align-items: center;
          .text-size-increase {
            @include breakpoint(small down) {
              display:none;
            }
          }
        }
        .header-menu-button {
          position: relative;
          min-height: 55px;
          max-height: 55px;
          line-height: 51px;
          border-radius: $border-radius;
          @include breakpoint(small down) {
            padding: 0!important;
            min-width: 61px;
            max-width: 61px;
            display: block;
          }
          &.button-help-centre {
            position: relative;
            padding: 0 30px 0 55px;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-right-color: transparent;
            border-right: 0;
            border-radius: $border-radius;
            outline-color: brand-color(primary-blue);
            display: flex;
            flex-direction: row;
            min-height: 55px;
            max-height: 55px;
            line-height:51px;
            i.icon-help {
              font-size: $button-icon-size-lg;
              position: absolute;
              left: 15px;
              top: 11px;
            }
          }
          &.button-side-menu {
            padding: 0 50px 0 20px;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border-left-color: transparent !important;
            border-left: 0;
            outline-color: brand-color(grey-light);
            i.icon-hamburger {
              font-size: $button-icon-size-md;
              position: absolute;
              right: 18px;
              top: 15px;
            }
  
            i.icon-close {
              font-size: $button-icon-size-sm;
              position: absolute;
              right: 15px;
              top: 16px;
            }
          }
        }
      }
    }
  }
  
  ul {
    @include unlist();
    line-height: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
