/*
There are also mixins inside the following files:

- \scss\_typography.scss
- \scss\_grid.scss

*/

// a list without standard list styling
@mixin unlist {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    &:before {
      display: none; // will hide the BULLET (which is set in _typography.scss)
    }
  }
}