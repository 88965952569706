// Shared Header styles here.
.Message {
  background: #fff;
  border-bottom: 2px solid #f0f0f0;
  color: #1e5892;
  font-size: $text-size-larger;
  font-family: $varela-font-family;
  .inner {
    padding-top: 16px;
    padding-bottom: 16px;
    .text-wrapper {
      text-align: center;
      @include breakpoint(medium down) {
        text-align: left;
      }

      * {
        font-size: inherit;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
