.desktop-content {
  display: none;
  @include breakpoint(medium up) {
    display: block;
  }
  @media print {
    display: block;
  }
}
.mobile-content {
  @include breakpoint(medium up) {
    display: none;
  }
  @media print {
    display: none;
  }
}
.img-no-resize {
  max-width: none;
  width: auto;
  height: auto;
}
.content {
  > .row {
    position: relative;
    @include breakpoint(medium up) {
      .search-page & {
        padding-top: rem-calc(130);
      }
    }
    @include breakpoint(large) {
      .search-page & {
        padding-top: rem-calc(120);
      }
    }
  }
  .page-title {
    color: brand-color(primary-blue);
    font-family: "Varela Round", Lato, sans-serif;
    font-size: rem-calc(30);
    line-height: rem-calc(36);
    margin: rem-calc(0 0 16);
    font-weight: 400;
    .search-page & {
      font-size: rem-calc(24);
      line-height: rem-calc(30);
      margin: rem-calc(0 0 20);
    }
    @include breakpoint(medium up) {
      font-size: rem-calc(60);
      line-height: rem-calc(70);
      margin: rem-calc(0 0 24);
      .search-page & {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
        margin: rem-calc(0 0 30);
      }
    }
  }
  &.no-left-menu {
    .main-article {
      @include breakpoint(medium up) {
        width: 100%;
      }
    }
  }

  .main-article {
    color: brand-color(body);

    font-family: Lato, sans-serif;
    
    .sectionHidden {
      .scfSectionBorderAsFieldSet,
      fieldset {
        padding: 0;
        border: 0;
        margin: rem-calc(8 0);
      }
    }
    .scfSubmitButtonBorder {
      input {
        &[type="submit"] {
          float: right;
        }
      }
    }
    .scfSectionContent {
      .scfCaptchaLimitGeneralPanel {
        table {
          margin-bottom: 0;
          max-width: rem-calc(400);
        }
      }
    }
    &.search-results {
      .result {
        margin-bottom: rem-calc(34);
        @include breakpoint(medium up) {
          margin-bottom: rem-calc(42);
        }
        &:last-child {
          margin-bottom: 0;
        }
        h2 {
          margin-bottom: rem-calc(7);
        }
        p {
          &:last-of-type {
            margin-bottom: rem-calc(4);
          }
        }
      }
    }
    .pagination {
      position: relative;
      text-align: center;
      &:after {
        content: "";
        display: table;
        clear: both;
      }
      .pagination-previous {
        position: absolute;
        top: rem-calc(30);
        left: 0;
        width: 25%;
        @include breakpoint(medium up) {
          top: 1.75rem;
          left: 25%;
          width: auto;
        }
      }
      .pagination-next {
        position: absolute;
        top: rem-calc(30);
        right: 0;
        width: 25%;
        text-align: right;
        @include breakpoint(medium up) {
          top: 1.75rem;
          right: 28%;
          width: auto;
        }
      }
      ul {
        margin: rem-calc(8) 0 0 25%;
        width: 50%;
        border-left: 1px solid brand-color(primary-blue);
        border-right: 1px solid brand-color(primary-blue);
        text-align: center;
        line-height: rem-calc(18);
        @include breakpoint(medium up) {
          width: 30%;
          margin: 0 auto;
          border-left-width: rem-calc(2px);
          border-right-width: rem-calc(2px);
        }
        &:after {
          content: "";
          display: table;
          clear: both;
        }
        li {
          display: inline-block;
          padding: rem-calc(4);
          margin: rem-calc(1 1);
          &:before {
            display: none;
          }
        }
      }
    }
  }
  .main-article,
  .left-menu {
    .intro-content {
      font-family: "Varela Round", Lato, sans-serif;
      color: brand-color(primary-light-blue);
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      margin-bottom: rem-calc(26);
      @include breakpoint(medium up) {
        font-size: rem-calc(22);
        line-height: rem-calc(26);
      }
      p {
        color: brand-color(primary-blue);
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        @include breakpoint(medium up) {
          font-size: rem-calc(22);
          line-height: rem-calc(26);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    h1 {
      font-family: "Varela Round", Lato, sans-serif;
      font-size: rem-calc(24);
      font-weight: 400;
      line-height: rem-calc(28);
      margin: rem-calc(20 0 20);
      color: brand-color(primary-blue);
      &:first-child {
        margin-top: 0;
      }
      @include breakpoint(medium up) {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
      }
    }

    ul {
      margin: rem-calc(26 0 24);
      padding: 0;
      list-style: none;
      @media print {
        list-style-type: square !important;
        list-style-position: outside;
        padding-left: rem-calc(40);
      }
      @include breakpoint(medium up) {
        margin-bottom: rem-calc(28);
      }
      li {
        color: brand-color(body);
        font-size: rem-calc(16);
        line-height: rem-calc(18);
        font-weight: 500;
        padding: rem-calc(3 0 3 70);
        position: relative;
        @media print {
          list-style-type: square !important;
          display: list-item;
          padding-left: 0;
        }
        @include breakpoint(medium up) {
          line-height: rem-calc(24);
          padding: rem-calc(1 0 1 70);
        }
        &:before {
          background-color: brand-color(primary-light-blue);
          content: "";
          height: rem-calc(7);
          position: absolute;
          left: rem-calc(48);
          top: rem-calc(8);
          width: rem-calc(7);
          @media print {
            display: none;
          }
          @include breakpoint(medium up) {
            height: rem-calc(8);
            width: rem-calc(8);
          }
        }
      }
    }
    h2 {
      font-size: rem-calc(20);
      line-height: rem-calc(24);
      margin: rem-calc(0 0 18);
      color: brand-color(primary-light-blue);
      @include breakpoint(medium up) {
        font-size: rem-calc(30);
        line-height: rem-calc(36);
        margin-bottom: rem-calc(24);
      }
    }
    .disclaimer {
      background-color: #f3f6fa;
      padding: rem-calc(24 20);
      margin-bottom: rem-calc(20);
      @include breakpoint(medium up) {
        padding: rem-calc(24 32 32);
      }
      h2 {
        font-size: rem-calc(18);
        line-height: rem-calc(22);
        margin-bottom: rem-calc(14);
        @include breakpoint(medium up) {
          font-size: rem-calc(22);
          line-height: rem-calc(26);
          margin-bottom: rem-calc(18);
        }
      }
    }
    table {
      border: none;

      &.tick-list {
        margin-left: 2rem;
        width: auto;
      }
    }
    table tr td,
    table tr th {
      color: black;
      font-size: rem-calc(14);
      line-height: rem-calc(18);
      @include breakpoint(medium up) {
        font-size: rem-calc(17);
        line-height: rem-calc(24);
      }
    }
    table tr.alt,
    table tr.even,
    table tr:nth-of-type(even) {
      background: none;
    }
    blockquote {
      font-style: italic;
      font-size: rem-calc(14);
      line-height: rem-calc(16);
      color: brand-color(primary-light-blue);
      font-weight: 400;
      border-left: 3px solid brand-color(primary-light-blue);
      margin-bottom: rem-calc(22);
      padding: rem-calc(0 0 1 22);
      width: auto;
      margin-left: rem-calc(52);
      @supports (width: calc(100% - #{rem-calc(52)})) {
        width: calc(100% - #{rem-calc(52)});
        margin-left: rem-calc(52);
      }
      @media print {
        border-top: 0;
        border-bottom: 0;
        border-right: 0;
      }

      p {
        color: brand-color(primary-blue);
        font-size: rem-calc(14);
        line-height: rem-calc(16);
        @include breakpoint(medium up) {
          font-size: rem-calc(17);
          line-height: rem-calc(24);
        }
      }

      @include breakpoint(medium up) {
        border-left: 8px solid brand-color(primary-light-blue);
        font-size: rem-calc(17);
        line-height: rem-calc(24);
        padding: rem-calc(0 0 0 30);
        margin: rem-calc(40 0 40 0);
        margin-left: rem-calc(50);
        width: auto;
        @supports (width: calc(100% - #{rem-calc(52)})) {
          width: calc(100% - #{rem-calc(50)});
          margin-left: rem-calc(50);
        }
      }
    }

    ul {
      li {
        @include breakpoint(medium up) {
          padding-left: rem-calc(97px);
        }
      }
    }
    ul.tick-list,
    ul.exclamation-list {
      li {
        padding-left: 6rem;

        &:before {
          background-color: transparent;
          background-repeat: no-repeat;
          background-size: contain;
          content: "";
          display: inline-block;
          height: rem-calc(29px);
          position: absolute;
          left: 2.375rem;
          top: 0px;
          width: rem-calc(29);
        }
      }
    }

    ul.tick-list {
      li:before {
        background-image: url("/~/media/dbi/images/icons/check.png?la=en&h=23&w=29");
      }
    }

    ul.exclamation-list {
      li {
        padding-bottom: 1rem;
        padding-left: 6rem;
      }

      li:before {
        background-image: url("/~/media/dbi/images/icons/exclamation-mark.png?la=en");
        background-position: center center;
      }
    }
  }
//   .left-menu {
//     margin: rem-calc(36 0 0);
//     @include breakpoint(medium up) {
//       margin: rem-calc(4) 4% 0 0;
//       float: left;
//       width: 29.3333%;
//     }
//     @media print {
//       display: none;
//     }

//     ul {
//       li {
//         @include breakpoint(medium up) {
//           padding: rem-calc(1 0 1 42);
//         }
//         &:before {
//           left: rem-calc(20);
//         }
//       }
//     }
//     .section-area {
//       margin-bottom: rem-calc(24);
//       > .row {
//         margin: 0;
//       }
//       .tile-area {
//         .tile {
//           width: 100%;

//           &.arrows {
//             padding: rem-calc(56 20 32);
//             &:after {
//               border-left-color: transparent;
//               border-right: rem-calc(33) solid transparent;
//               border-bottom: none;
//               border-top-color: inherit;
//               right: auto;
//               left: 50%;
//               top: 100%;
//               margin-top: rem-calc(-3);
//               margin-left: rem-calc(-33);
//             }
//             &:first-child {
//               padding-top: rem-calc(32);
//             }
//             &:last-child {
//               &:after {
//                 display: none;
//               }
//             }
//           }
//         }
//       }
//     }
//     blockquote {
//       @include breakpoint(medium up) {
//         margin-left: 5%;
//         width: 95%;
//         @supports (width: calc(100% - #{rem-calc(52)})) {
//           width: calc(100% - #{rem-calc(10)});
//           margin-left: rem-calc(10);
//         }
//       }
//       @include breakpoint(large) {
//         margin-left: 7.5%;
//         width: 92.5%;
//         @supports (width: calc(100% - #{rem-calc(52)})) {
//           width: calc(100% - #{rem-calc(20)});
//           margin-left: rem-calc(20);
//         }
//       }
//     }

//     .left-menu-nav {
//       margin: 0;
//       padding: 0;
//       list-style: none;
//       border-top: 1px solid #d3d8de;

//       + * {
//         margin-top: rem-calc(24);
//       }

//       li {
//         padding: 0;
//         &:before {
//           display: none;
//         }
//       }

//       a {
//         color: brand-color(primary-blue);
//         display: block;
//         font-family: "Varela Round", Lato, sans-serif;
//         font-size: rem-calc(18);
//         line-height: rem-calc(22);
//         padding: rem-calc(9 20 10);
//         border-bottom: 1px solid #d3d8de;
//         transition: all 250ms ease-out;
//         &:hover {
//           background-color: #f3f6fa;
//         }
//       }
//       .current-page {
//         a {
//           background-color: #f3f6fa;
//         }
//       }
//     }
//   }
}
