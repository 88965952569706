.InfoPanel {
  &.mute-blue {
    background-color: brand-color(mute-blue);
  }
  min-height: 300px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .info-panel-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    .info-panel-title {
      max-width: 768px;
      margin: 0 auto;
      margin-bottom: 25px;
      h2 {
        text-align: center;
        color: brand-color(primary-blue);
        font-size: $text-size-xlarge-lh;
        font-weight: 700;
      }
    }

    .info-panel-content-container {
      .info-panel-content {
        .info-panel-links {
          .info-panel-subheading {
            color: brand-color(primary-light-blue);
            font-size: $text-size-larger-lh;
            margin-bottom: 5px;
          }
          ul {
            margin: 10px 0 20px 0;
            li {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              i {
                font-size: $button-icon-size-xs;
                margin-right: 10px;
              }
              a {

                line-height: $list-line-height-sm;
                font-size: $base-font-size;
              
              }
            }
          }
          .button {
            color: brand-color(primary-light-blue);
            
            i {
              color: brand-color(primary-light-blue);
            }
          }
        }
      }
      .info-panel-video-block {
        @include breakpoint(medium down) {
          margin-top: 40px;
          padding: 0;
          margin-left: auto;
          margin-right: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }
}
